<template>
    <div class="home-slider">
        <div class="text-center my-3 mb-50">
            <div class="img">
                <img class="img-fluid" src="@/assets/images/listing.png" alt="Listing Image">
            </div>
        </div>

    </div>
</template>

<script>


    export default {
        name: 'homeSlider',

        data() {
            return {}
        },

    };
</script>

<style lang="scss" scoped>
    .mb-50 {
        @media (max-width: 768px) {
            margin-bottom: 100px !important;
        }
    }

    .img {
        img {
            height: 435px;
            width: 435px;
            max-width: 500px;
            @media (max-width: 1024px) {
                max-width: 100%;
                width: auto;
        }
        }
    }

</style>
