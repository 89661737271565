<template>
  <div class="container search-container-wrap">
    <div class="search-container">
      <!-- tabs With Forms -->

      <!-- Row With Forms -->
      <form class="row with-forms">
        <!-- Place -->
        <div class="col-12 mb-4">
          <h2
            class="font-weight-bold font-28 mobile-d-flex"
            v-if="agentLocator"
          >
            ابدأ رحلة البحث عن
            <span class="brand-color"> الجهات عقاريه</span> ...
          </h2>
          <h2 class="font-weight-bold font-28 mobile-d-flex" v-else>
            ابدأ رحلة البحث عن <span class="brand-color">العقارات</span> …
          </h2>
          <p v-if="agentLocator" class="mb-4">
            ابحث عن الجهات العقاريه واحصل علي معلومات التواصل معها والوصول
            اليها..
          </p>
          <p v-else class="mb-4">
            ابحث، بيع و أستأجر. إبدأ الآن فهناك الكثير من الخيارات الرائعة في
            انتظارك..
          </p>
        </div>

        <div class="col-12 mb-4 min-height-90">
          <div>
            <input
              v-model="agentToggal"
              class="radio-btn"
              type="radio"
              name="radios"
              id="radio1"
              :value="false"
              checked
            />
            <label class="h4" for="radio1">عقارات</label>

            <input
              v-model="agentToggal"
              class="radio-btn"
              type="radio"
              name="radios"
              id="radio2"
              :value="true"
            />
            <label class="h4" for="radio2"> جهات عقاريه</label>
          </div>
          <b-form-radio-group
            v-show="agentToggal === false"
            id="filter-status-group"
            v-model="propertyStatus"
            :options="searchBarItems"
            value-field="_id"
            text-field="nameAr"
            name="radios-btn-default"
          >
          </b-form-radio-group>
        </div>

        <div class="col-12 mb-4">
          <div class="main-search-input">
            <gmap-autocomplete
              id="autosuggest__input_home"
              :placeholder="city ? city : 'ابحث باسم الحي أو المدينة'"
              class="form-control"
              :component-restrictions="{ country: 'sa' }"
              @place_changed="setPlace"
            >
            </gmap-autocomplete>
            <span class="icon hi-map-pin"></span>
          </div>
        </div>

        <!-- Property Type -->
        <div class="col-12 mb-4 type-status-field">
          <!-- {{realEstateTypes}} -->
          <b-form-group>
            <multiselect
              v-model="propertyType"
              :value="propertyType"
              track-by="_id"
              :label="agentLocator ? '' : 'nameAr'"
              :show-labels="false"
              :placeholder="
                agentLocator ? 'اختر نوع مكتبك العقاري' : 'اختر نوع العقار'
              "
              :options="agentLocator ? agentLocatorType : newRealestatTypes"
              @input="submit"
              id="propertyInputSearch"
            >
              <template slot="noOptions">لا يوجد نوع عقار</template>
              <template slot="noResult">لا يوجد نوع عقار بهذا الاسم</template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-button
            variant="primary"
            type="submit"
            class="w-100"
            @click.stop.prevent="submit()"
          >
            <i class="hi-magnifier ml-2"></i>
            ابحث الآن
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'SearchAreaBar',
  components: {
    Multiselect
  },
  data() {
    return {
      bg: require('../../assets/images/home-parallax-2.jpg'),
      place: null,
      propertyType: null,
      propertyStatus: 0,

      // current info
      currentPlace: null,
      // currentFormattedAddress: null,
      city: null,
      state: null,
      postal_code: null,
      newRealestatTypes: [],
      searchBarItems: [],
      agentLocatorType: ['شركه عقاريه', 'مكتب عقاري'],
      agentToggal: false
    }
  },
  computed: {
    ...mapState('accountState', [
      'configs',
      'realEstateStatus',
      'realEstateTypes'
    ]),
    ...mapState('agentLocator', ['agentLocator']),
    ...mapGetters('accountState', ['getConfigNameById', 'getConfigIdByName']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    google: gmapApi
  },
  created() {
    this.realestatTypes()
    this.searchBarItems = [
      { nameAr: 'الكل', _id: 0 },
      {
        nameAr: 'للأيجار',
        _id: this.getConfigIdByName('realEstateStatus', 'rent')
      },
      {
        nameAr: 'للبيع',
        _id: this.getConfigIdByName('realEstateStatus', 'sale')
      }
    ]
    //getConfigIdByName('realEstateStatus', 'rent')
  },
  watch: {
    agentToggal(val) {
      this.setAgentLocator(val)
    }
  },
  mounted() {
    this.setAgentLocator(false)
    setTimeout(() => {
      this.geolocate()
    }, 2000)
  },
  methods: {
    // receives a place object via the autocomplete component
    ...mapMutations('realEstateState', ['setMapCenter', 'setPropertiesTitle']),
    ...mapMutations('agentLocator', ['setAgentLocator']),
    setPlace(place) {
      this.place = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
      this.setMapCenter(this.place)
      this.setPropertiesTitle(place.adr_address)
    },

    geolocate: function () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.currentPlace = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          this.getFormattedAddress(this.currentPlace)
        })
      }
    },

    getFormattedAddress(location) {
      // Convert the latlang to formatted address
      var geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ latLng: location }, (results) => {
        results.forEach((element) => {
          element.address_components.forEach((element2) => {
            let types = element2.types
            if (types[0] === 'locality' && types[1] === 'political') {
              this.city = element2.long_name
            }
          })
        })
        // this.currentFormattedAddress = results[0].formatted_address
      })
    },

    submit() {
      let params = {}
      // let promise = new Promise(resolve => {
      if (this.place) {
        params.lat = this.place.lat
        params.lng = this.place.lng
      } else if (this.currentPlace) {
        // go to current location
        params.lat = this.currentPlace.lat
        params.lng = this.currentPlace.lng
      } else {
        //Restore default search loaction to default (Riyadh)
        this.setMapCenter({ lat: 24.7135517, lng: 46.67529569999999 })
      }
      if (this.agentLocator)
        return this.$router.push({
          name: 'agents',
          params: { id: 'default' },
          query: Object.assign(this.$route.query, params)
        })
      if (this.propertyType) {
        params.type = this.propertyType._id
      }
      if (this.propertyStatus) {
        params.status = this.propertyStatus
      }
      this.$router.push({
        name: 'properties',
        params: { id: 'default' },
        query: Object.assign(this.$route.query, params)
      })
    },

    realestatTypes() {
      this.newRealestatTypes = this.realEstateTypes
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-btn {
  appearance: none;
  display: none;
  height: 0;
  width: 0;

  & + label {
    display: inline-block;
    margin-bottom: 0.5em;
    margin-right: -5px;
    padding: 0.75em 1.25em;
    color: #3d3d3d;
    width: 160px;

    text-align: center;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    @media (max-width: 575px) {
      width: auto !important;
      min-width: 145px;
      font-size: 1.1rem;
    }
  }

  &:checked + label {
    background-color: #3dba7e;
    color: white;
    width: 160px;
    text-align: center;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}

.font-28 {
  font-size: 28px;
  color: #3d3d3d;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.main-search-input,
.multiselect {
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.min-height-90 {
  min-height: 90px;
}
</style>
