<template>
  <div>
    <div v-if="loader" class="loading-wrap">
      <div class="inner-wrap isActive">
        <circle2></circle2>
      </div>
    </div>
    <div id="home-area" class="position-relative">
      <!-- <div class="home-bg" :style='{ backgroundImage: `url("${homeBg}")` }'></div> -->
      <!-- Search area -->
      <div class="container">
        <div class="row">
          <div class="col-md-6 p-0">
            <search-bar />
          </div>
          <div class="col-md-6 p-0">
            <homeSlider />
          </div>
        </div>
      </div>
      <div class="position-absolute bottom-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6">
            <b-nav class="d-flex align-items-center justify-content-start">
              <!-- <b-nav-item to="/faq"> الأسئلة المتكررة
                  <span class="pr-2 font-18 font-weight-bolder">.
                  </span>
              </b-nav-item> -->
              <b-nav-item @click.passive="helpCenterRout()">
                مركز المساعده</b-nav-item
              >
              <b-nav-item @click.passive="termsconditionsRout()">
                الشروط والأحكام</b-nav-item
              >
            </b-nav>
          </div>
          <div
            class="col-md-6 d-flex align-items-center p-0 justify-content-end app--download--btns"
          >
            <h6 class="ml-3">
              حمل تطبيق <span class="brand-color">هكتار</span> الآن!
            </h6>

            <a
              class="mx-2"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.hectar.hectarapp"
            >
              <img
                src="@/assets/images/goolge.svg"
                alt="Google App Download Icon"
                loading="lazy"
              />
            </a>
            <a
              target="_blank"
              href="https://apps.apple.com/eg/app/hectar-%D9%87%D9%83%D8%AA%D8%A7%D8%B1/id1560571291"
            >
              <img
                src="@/assets/images/apple.svg"
                alt="Apple App Download Icon"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/home/SearchBar.vue'
import { Circle2 } from 'vue-loading-spinner'
import config from '@/config'
import { mapState } from 'vuex'
import homeSlider from '../../components/home/homeSlider.vue'
import openInAnewTab from '../../mixins/newTab.js'

export default {
  layout: 'main',
  components: {
    SearchBar,
    Circle2,
    homeSlider
  },
  mixins: [openInAnewTab],
  data() {
    return {
      homeBg: require('../../assets/images/cities-bgs/white-text.jpg'),
      googlePlayApp: require('../../assets/images/google-play-badge.svg'),
      iosApp: require('../../assets/images/app-store-badge.svg'),
      loader: false
    }
  },
  watch: {
    agentLocator() {
      this.loader = true
      setTimeout(() => {
        this.loader = false
      }, 600)
    }
  },
  computed: {
    ...mapState('agentLocator', ['agentLocator']),
    ...mapState('accountState', ['loggedIn', 'user']),
    iosAppUrl() {
      return config.iosAppUrl
    },
    androidAppUrl() {
      return config.androidAppUrl
    }
  },
  methods: {
    helpCenterRout() {
      if (this.loggedIn) {
        this.openInAnewTab('help-center')
      } else {
        this.$router.push('/auth/login')
      }
    },
    termsconditionsRout() {
      this.openInAnewTab('terms&conditions')
    }
  }
}
</script>
