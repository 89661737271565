export default {
  methods: {
    openInAnewTab(route = '') {
      const routeData = this.$router.resolve({
        name: route,
        query: {
          type: 'hectar',
          name: this.user.name,
          phone: this.user.phoneNumber,
          id: this.user.accountId
        }
      })
      return window.open(routeData.href, '_blank')
    }
  }
}
